<template lang="pug">
  Modal(
    :is-open="!!component"
    :title="title"
    :hide-header="hideHeader"
    :header-line="headerLine"
    :size="size"
    :background="background"
    @close="handleOutsideClick"
  )
    component(
      :is="component"
      v-bind="props"
      @close="handleClose"
    )
</template>

<script>
  // mixins
  import withConfirmation from "@/mixins/withConfirmation"

  // misc
  import { bus } from "@/config"
  import { ESC_KEY_CODE } from "@/config/constants"

  const defaultData = {
    component: null,
    title: "",
    props: null,
    closeOnClick: true,
    hideHeader: false,
    headerLine: true,
    size: "default",
    closeOnEsc: true,
    background: "white",
    emitClose: false,
    useConfirm: false,
    hasUnsavedChanges: false
  }

  export default {
    components: {
      Modal: () => import("@/components/elements/AppModal/Modal")
    },

    mixins: [withConfirmation],

    data() {
      return { ...defaultData }
    },

    created() {
      bus.on("open-modal", this.handleOpenModal)
      bus.on("set-modal-unsaved-changes", this.setUnsavedChanges)
    },

    beforeDestroy() {
      bus.off("open-modal", this.handleOpenModal)
      bus.off("set-modal-unsaved-changes", this.setUnsavedChanges)
    },

    methods: {
      setUnsavedChanges(value) {
        this.hasUnsavedChanges = value
      },

      handleOpenModal(data) {
        Object.assign(this.$root, { modalOpened: true })
        this.assignData(data)

        if (this.closeOnEsc) {
          this.addKeyupListener()
        }
      },

      assignData(data) {
        Object.assign(this.$data, { ...defaultData, ...data })
      },

      addKeyupListener() {
        document.addEventListener("keyup", this.handleKeyup)
      },

      removeKeyupListener() {
        document.removeEventListener("keyup", this.handleKeyup)
      },

      handleKeyup(e) {
        if (e.keyCode === ESC_KEY_CODE) this.handleClose()
      },

      handleOutsideClick(force = false) {
        if (!this.closeOnClick && !force) return

        if (this.emitClose) bus.emit("close-modal")
        this.handleClose()
      },

      handleClose() {
        this.$conditionalConfirm({
          useConfirm: this.useConfirm && this.hasUnsavedChanges,
          handler: () => {
            this.component = null
            this.removeKeyupListener()
            Object.assign(this.$root, { modalOpened: undefined })
          }
        })
      }
    }
  }
</script>
